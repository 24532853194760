import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Abiotické ekologické faktory" />
    <h1>Abiotické ekologické faktory</h1>

    <p>Abiotick&eacute; ekologick&eacute; faktory zahrnuj&iacute; vlivy neživ&eacute;ho prostřed&iacute;. Stejně jako <Link to="/biogeografie/ekologicka/ekologicke-faktory/">v&scaron;echny ekologick&eacute; faktory</Link> limituj&iacute; v&yacute;skyt organismů a podmiňuj&iacute; tak jejich geografick&eacute; roz&scaron;&iacute;řen&iacute;. Do abiotick&yacute;ch faktorů řad&iacute;me zejm&eacute;na:</p>
    <ul>
    <li><strong>světlo</strong></li>
    <li><strong>teplota</strong> (mr&aacute;z)</li>
    <li><strong>sr&aacute;žky</strong></li>
    <li><strong>půdn&iacute; vlhkost</strong></li>
    <li><strong>půdn&iacute; reakce</strong></li>
    <li><strong>typ podlož&iacute;</strong></li>
    <li><strong>oheň</strong></li>
    </ul>
    <hr />
    <h2>Faktor slunečn&iacute;ho z&aacute;řen&iacute; (světlo)</h2>
    <p>Množstv&iacute; dostupn&eacute;ho slunečn&iacute;ho z&aacute;řen&iacute; (či jednodu&scaron;e světla) je z&aacute;kladn&iacute;m abiotick&yacute;m ekologick&yacute;m faktorem. <strong>Množstv&iacute; energie</strong> dopadaj&iacute;c&iacute; na povrch Země <strong>se měn&iacute; v z&aacute;vislosti předev&scaron;&iacute;m na</strong>:</p>
    <ul>
    <li><strong>postaven&iacute; Slunce a zeměpisn&eacute; &scaron;&iacute;řce </strong></li>
    <li><strong>expozici a sklonu ozařovan&eacute; plochy </strong>(v podm&iacute;nk&aacute;ch Česka je nejv&iacute;ce energie na J, JZ a JV svaz&iacute;ch o sklonu 25-30&deg;)</li>
    <li><strong>zacloněn&iacute; horizontem a oblačnosti</strong></li>
    </ul>
    <h3>Vliv ekologick&eacute;ho faktoru světla na rostliny</h3>
    <p>Ekologick&yacute; faktor světla m&aacute; mnoho vlivů na niku i v&yacute;voj rostlin. Rostliny např&iacute;klad <strong>preferuj&iacute; různ&eacute; světeln&eacute; podm&iacute;nek</strong> a dle toho je lze dělit na:</p>
    <ul>
    <li><strong>heliofyty</strong> = rostliny nesn&aacute;&scaron;ej&iacute;c&iacute; zast&iacute;něn&iacute;</li>
    <li><strong>heliosciofyty</strong> = rostliny, kter&yacute;m nevad&iacute; zast&iacute;něn&iacute; ani nadměrn&yacute; osvit</li>
    <li><strong>sciofyty</strong> = rostliny preferuj&iacute;c&iacute; zast&iacute;něn&iacute; a nesn&aacute;&scaron;ej&iacute;c&iacute; nadměrn&yacute; osvit</li>
    </ul>
    <p><strong>Sez&oacute;nn&iacute; změny</strong> v množstv&iacute; z&aacute;řen&iacute; pak <strong>ovlivňuj&iacute; časovou niku rostlin</strong>. Světlomiln&eacute; byliny v lesn&iacute;m podrostu jsou nuceny kv&eacute;st časně z jara, dř&iacute;ve než se olist&iacute; les nad nimi a zamez&iacute; jim t&iacute;m př&iacute;stup ke světlu. Tyto světlomiln&eacute; druhy kvetouc&iacute; časně z jara označujeme souhrnně jako <strong>jarn&iacute; efem&eacute;ry</strong>. Naopak po olistěn&iacute; stromů nastupuj&iacute; v podrostu st&iacute;nomiln&eacute; druhy kvetouc&iacute; později, často až v pozdn&iacute;m l&eacute;tě.</p>
    <p class="fotopoerioda">Se světlem je &uacute;zce spojen pojem <strong>fotoperioda</strong>, kter&yacute; označuje d&eacute;lku denn&iacute;ho světla za 24&nbsp;hodin. V t&eacute;to době je rostlina schopna fotosyntetizovat. Na z&aacute;kladě toho, jak se fotoperioda zkracuje, nebo prodlužuje, může rostlina ř&iacute;dit sv&eacute; kveten&iacute;, kl&iacute;čen&iacute; a růst a opad listů.</p>
    <p>Čast&aacute; může b&yacute;t i <strong>fototaxe</strong>, kter&aacute; je definov&aacute;na jako směrově orientovan&yacute; pohyb ke světlu (pozitivn&iacute; fototaxe) nebo od světla (negativn&iacute; fototaxe).</p>
    <h4>Fotosyntetick&eacute; strategie rostlin</h4>
    <p>Množstv&iacute; světla tak&eacute; ovlivňuje intenzitu fotosynt&eacute;zy a fotosyntetick&eacute; strategie rostlin. Fotosyntetick&aacute; strategie je konkr&eacute;tn&iacute; typ průběhu přeměny energie slunečn&iacute;ho z&aacute;řen&iacute; na energii v&aacute;zanou v chemick&yacute;ch sloučenin&aacute;ch (gluk&oacute;ze). Dle fotosyntetick&eacute; strategie rozli&scaron;ujeme z&aacute;kladn&iacute; 3&nbsp;druhy rostlin:</p>
    <ul>
    <li><strong>C3 rostliny</strong> (rostliny m&iacute;rn&eacute;ho p&aacute;smu, např. p&scaron;enice a ječmen, CO2 zabudov&aacute;v&aacute;n do 3-uhl&iacute;kat&yacute;ch sloučenin)</li>
    <li><strong>C4 rostliny</strong> (vět&scaron;inou tropick&eacute; tr&aacute;vy, slanomiln&eacute; či ruder&aacute;ln&iacute; druhy, např. kukuřice a proso, CO2 zabudov&aacute;v&aacute;n do 4-uhl&iacute;kat&yacute;ch sloučenin, vysok&eacute; tepeln&eacute; optimum fotosynt&eacute;zy)</li>
    <li><strong>CAM rostliny</strong> (přev&aacute;žně such&eacute; pou&scaron;tn&iacute; oblasti, např. ananas či aloe, otev&iacute;r&aacute;n&iacute; průduchů v noci kvůli předch&aacute;zen&iacute; ztr&aacute;t&aacute;m vody, jinak podobn&eacute; C4)</li>
    </ul>
    <h3>Rostliny ČR ve vztahu ke světlu</h3>
    <p>V tabulce n&iacute;že jsou uvedeny 3&nbsp;rostliny s n&iacute;zkou a vysokou <Link to="/biogeografie/ekologicka/ekologicke-faktory/#ellenberg">Ellenbergovskou indikačn&iacute; hodnotou</Link> běžně se vyskytuj&iacute;c&iacute; na &uacute;zem&iacute; Česka. V z&aacute;vorce je pod česk&yacute;m a latinsk&yacute;m jm&eacute;nem druhu uvedena dan&aacute; Ellenbergova indikačn&iacute; hodnota (EIH).</p>
    <figure class="wp-block-table">
    <table>
    <thead>
    <tr>
    <th>N&iacute;zk&aacute; indikačn&iacute; hodnota (EIH)</th>
    <th>Vysok&aacute; indikačn&iacute; hodnota (EIH)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>&scaron;ťavel kysel&yacute;<br /><em>Oxalis acetosella</em><br /><em>L2</em></td>
    <td>oman oko Kristovo<br /><em>Inula oculus-christi</em><br /><em>L9</em></td>
    </tr>
    <tr>
    <td>bukovn&iacute;k kapraďovit&yacute;<br /><em>Gymnocarpium dryopteris</em><br /><em>L2</em></td>
    <td>netřesk v&yacute;běžkat&yacute;<br /><em>Jovibarba globifera</em><br /><em>L9</em></td>
    </tr>
    <tr>
    <td>jedle bělokor&aacute;<br /><em>Abies alba</em><br /><em>L3</em></td>
    <td>pry&scaron;ec chvojka<br /><em>Euphorbia cyparissias</em><br /><em>L8</em></td>
    </tr>
    </tbody>
    </table>
    </figure>
    <h3>Vliv ekologick&eacute;ho faktoru světla na živočichy</h3>
    <p>Ekologick&yacute; faktor světla ovlivňuje i živočichy. Podobně jako rostliny je lze rozdělit s ohledem na vztah ke světlu na:</p>
    <ul>
    <li><strong>heliofiln&iacute; živočichov&eacute;</strong></li>
    <li><strong>heliosciofiln&iacute; živočichov&eacute;</strong></li>
    <li><strong>sciofiln&iacute; živočichov&eacute;</strong></li>
    <li><strong>heliof&oacute;bn&iacute; živočichov&eacute;</strong></li>
    </ul>
    <p>Světlo, konkr&eacute;tně fotoperioda, ř&iacute;d&iacute; u živočichů <strong>biologick&eacute; rytmy</strong> (cirkadi&aacute;nn&iacute; a ultradi&aacute;nn&iacute; rytmy). Množstv&iacute; světla může t&eacute;ž způsobovat <strong>sez&oacute;nn&iacute; dimorfismus</strong>, tedy jinou morfologii organismu v různ&yacute;ch sez&oacute;n&aacute;ch. Př&iacute;kladem sez&oacute;nn&iacute;ho dimorfismu může b&yacute;t babočka s&iacute;ťkovan&aacute; (<em>Araschnia levana</em>), jej&iacute;ž jarn&iacute; forma je červenožlut&aacute; a letn&iacute; forma (hodnota <Link to="/biogeografie/ekologicka/#fotoperioda">fotoperiody</Link> nad 16 hodin) černohněd&aacute;.</p>
    <hr />
    <h2>Faktor teploty</h2>
    <p>Množstv&iacute; teploty je z&aacute;kladn&iacute;m abiotick&yacute;m ekologick&yacute;m faktorem, kter&yacute; je <strong>&uacute;zce nav&aacute;z&aacute;n na př&iacute;sun slunečn&iacute; energie</strong>. Teplota může b&yacute;t silně ovlivněna sezonalitou, a to:</p>
    <ul>
    <li><strong>denn&iacute; sezonalitou</strong> (vysok&aacute; amplituda teplot na denn&iacute; b&aacute;zi na hor&aacute;ch a v pou&scaron;t&iacute;ch, n&iacute;zk&aacute; u mořsk&eacute;ho pobřež&iacute;)</li>
    <li><strong>ročn&iacute; sezonalitou</strong> (vysok&eacute; sez&oacute;nn&iacute; amplitudy v oblastech s kontinent&aacute;ln&iacute;m klimatem, např. na Sibiři a jin&yacute;ch kontinent&aacute;ln&iacute;ch step&iacute;)</li>
    </ul>
    <p>Druhy se &scaron;irokou ekologickou amplitudou pro teplotu se označuj&iacute; pojmem <strong>eurytermn&iacute;</strong>, ty s &uacute;zk&yacute;m rozsahem ekologick&eacute; valence pro teplotu <strong>stenotermn&iacute;</strong>.</p>
    <h3>Vliv ekologick&eacute;ho faktoru teploty na rostliny</h3>
    <p>Z hlediska vlivu na rostliny je ekologick&yacute; faktor teploty důležit&yacute; zejm&eacute;na z hlediska extr&eacute;mů. A to zejm&eacute;na minim&aacute;ln&iacute; teplota nejchladněj&scaron;&iacute; měs&iacute;ce či sumy teplot (suma efektivn&iacute;ch teplot).</p>
    <p>Tolerance rostlin k teplotě se sez&oacute;nně často měn&iacute; a mnoho rostlinn&yacute;ch druhů m&aacute; např&iacute;klad vy&scaron;&scaron;&iacute; toleranci k n&iacute;zk&yacute;m teplot&aacute;m (mrazy apod.) mimo vegetačn&iacute; obdob&iacute;. Mrazy ve vegetačn&iacute;m obdob&iacute;m pro ně pak mohou b&yacute;t mimoř&aacute;dně nebezpečn&eacute;, proto <a href="http://portal.chmi.cz/informace-pro-vas/prezentace-a-vyuka/SIVS#">ČHM&Uacute; vyd&aacute;v&aacute; meteorologick&eacute; v&yacute;strahy</a> pro mr&aacute;z ve vegetačn&iacute;m obdob&iacute;m.</p>
    <p>Teplota m&aacute; v&yacute;znamn&yacute; vliv na fenologii a d&aacute;le na ztr&aacute;tu vody v&yacute;parem i&nbsp;d&yacute;ch&aacute;n&iacute;m.</p>
    <h4>Členěn&iacute; rostlin dle n&aacute;roků na teplo (de&nbsp;Candolle)</h4>
    <p>Rostliny lze členit podle průměrn&yacute;ch n&aacute;roků na teplo (sestavil de Candolle v roce 1874) do 4 z&aacute;kladn&iacute;ch skupin:</p>
    <ul>
    <li><strong>megatermy</strong> (tropick&eacute; rostliny, v&iacute;ce než 20 &deg;C)</li>
    <li><strong>mezotermy</strong> (subtropick&eacute; a mediter&aacute;nn&iacute; rostliny, 15-20 &deg;C)</li>
    <li><strong>mikrotermy</strong> (rostliny m&iacute;rn&eacute;ho p&aacute;smu, 0-14 &deg;C)</li>
    <li><strong>hekistotermy</strong> (arktick&eacute; a vysokohorsk&eacute; rostliny, m&eacute;ně než 0 &deg;C)</li>
    </ul>
    <h3>Rostliny ČR ve vztahu k teplotě</h3>
    <p>V tabulce n&iacute;že jsou uvedeny 3&nbsp;rostliny s n&iacute;zkou a vysokou <Link to="/biogeografie/ekologicka/ekologicke-faktory/#ellenberg">Ellenbergovskou indikačn&iacute; hodnotou</Link> běžně se vyskytuj&iacute;c&iacute; na &uacute;zem&iacute; Česka. V z&aacute;vorce je pod česk&yacute;m a latinsk&yacute;m jm&eacute;nem druhu uvedena dan&aacute; Ellenbergova indikačn&iacute; hodnota (EIH).</p>
    <figure class="wp-block-table">
    <table>
    <thead>
    <tr>
    <th>N&iacute;zk&aacute; indikačn&iacute; hodnota (EIH)</th>
    <th>Vysok&aacute; indikačn&iacute; hodnota (EIH)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>s&iacute;tina trojklann&aacute;<br /><em>Juncus trifidus</em><br /><em>T1</em></td>
    <td>katr&aacute;n tatarsk&yacute;<br /><em>Crambe tataria</em><br /><em>T8</em></td>
    </tr>
    <tr>
    <td>kukl&iacute;k horsk&yacute;<br /><em>Geum montanum</em><br /><em>T2</em></td>
    <td>dub &scaron;&iacute;p&aacute;k<br /><em>Quercus pubescens</em><br /><em>T8</em></td>
    </tr>
    <tr>
    <td>jetel alpsk&yacute;<br /><em>Trifolium alpinum</em><br /><em>T2</em></td>
    <td>kosatec n&iacute;zk&yacute;<br /><em>Iris pumila</em><br /><em>T8</em></td>
    </tr>
    </tbody>
    </table>
    </figure>
    <h3>Živočichov&eacute; a vliv teploty</h3>
    <p>Podle st&aacute;losti tělesn&eacute; teploty živočicha lze rozli&scaron;it dvě z&aacute;kladn&iacute; skupiny organismů:</p>
    <ul>
    <li><strong>poikilotermn&iacute;</strong> (živočichov&eacute; s kol&iacute;savou tělesnou teplotou)</li>
    <li><strong>homoitermn&iacute;</strong> (živočichov&eacute; se st&aacute;lou tělesnou teplotou)</li>
    </ul>
    <p>Dle toho, jak&yacute;m způsobem živočich reguluje svou tělesnou teplotu, pak naopak rozli&scaron;ujeme v z&aacute;kladu živočichy:</p>
    <ul>
    <li><strong>ektotermn&iacute;</strong> (živočichov&eacute; reguluj&iacute;c&iacute; svou teplotu na z&aacute;kladě vněj&scaron;&iacute;ho prostřed&iacute;, jsou př&iacute;mo limitov&aacute;n&iacute; teplotou prostřed&iacute;)</li>
    <li><strong>endotermn&iacute;</strong> (živočichov&eacute; reguluj&iacute;c&iacute; tělesnou teplotu pomoc&iacute; vlastn&iacute;ho metabolismu, pro jej&iacute; udržen&iacute; vyd&aacute;vaj&iacute; energii)</li>
    </ul>
    <p>V tabulce n&iacute;že naleznete kr&aacute;tk&eacute; shrnut&iacute; v&yacute;ho a nev&yacute;hod ektotermn&iacute; a&nbsp;endotermn&iacute; strategie u živočichů.</p>
    <figure class="wp-block-table is-style-stripes">
    <table class="has-fixed-layout">
    <thead>
    <tr>
    <th></th>
    <th>Ektotermn&iacute; živočichov&eacute;</th>
    <th>Endotermn&iacute; živočichov&eacute;</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td><strong>V&yacute;hody</strong></td>
    <td>+ St&aacute;l&aacute; teplota těla<br />+ Možnost aktivity i&nbsp;v&nbsp;nepř&iacute;zniv&yacute;ch podm&iacute;nk&aacute;ch<br />+ Vytrvalost pohybu<br />+ Zv&yacute;&scaron;en&aacute; reprodukčn&iacute; rychlost</td>
    <td>+ N&iacute;zk&eacute; energetick&eacute; n&aacute;roky<br />+ Vět&scaron;&iacute; morfologick&aacute; palsticita<br />+ Potravn&iacute; specializace a&nbsp;schopnost hladovět<br />+ Možnost ob&yacute;vat v&yacute;razně sez&oacute;nn&iacute; prostřed&iacute;</td>
    </tr>
    <tr>
    <td><strong>Nev&yacute;hody</strong></td>
    <td>- Energetick&aacute; n&aacute;ročnost, až 10x rychlej&scaron;&iacute; metabolismus<br />- Omezuje morfologii těla, selekce proti prot&aacute;hl&yacute;m, energeticky n&aacute;ročn&yacute;m tvarům</td>
    <td>- Z&aacute;vislost na vněj&scaron;&iacute;m prostřed&iacute;<br />- Men&scaron;&iacute; vytrvalost</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <hr />
    <h2>Faktor vody a vlhkosti v&nbsp;terestrick&eacute;m prostřed&iacute;</h2>
    <p>Zdrojem vlhkosti vzduchu i půd jsou zejm&eacute;na atmosferick&eacute; sr&aacute;žky, d&aacute;le povrchov&yacute; či podzemn&iacute; př&iacute;tok. Množstv&iacute; vody je tak&eacute; ovlivňov&aacute;no teplotou, prouděn&iacute;m vzduchu, vegetac&iacute;, vlastnostmi půd a podlož&iacute;. Kl&iacute;čov&aacute; je pro ekosyst&eacute;my mimo jin&eacute; sezonalita sr&aacute;žek, kter&aacute; je obecně velmi vysok&aacute; pod&eacute;l rovn&iacute;ku (monzuny v Asii, obdob&iacute; de&scaron;ťů v Africe apod.). Na v&yacute;par z prostřed&iacute; m&aacute; v&yacute;znamn&yacute; vliv reli&eacute;f, konkr&eacute;tně jeho expozice.</p>
    <h3>Vliv vlhkosti na rostliny a živočichy</h3>
    <p>Podle &scaron;&iacute;řky <Link to="/biogeografie/ekologicka/#ekologicka-amplituda">ekologick&eacute; amplitudy</Link> k vlhkosti, nebo tak&eacute; toleranci k různ&eacute; vlhkosti, lze rozli&scaron;ovat druhy:</p>
    <ul>
    <li><strong>euryhydrick&eacute;</strong> (druhy s vysokou toleranc&iacute; k různ&eacute; vlhkosti, &scaron;irok&aacute; amplituda)</li>
    <li><strong>stenohydrick&eacute;</strong> (druhy se specifick&yacute;mi požadavkami na vlhkost, &uacute;zk&aacute; amplituda)</li>
    </ul>
    <p>Podle preference určit&eacute; vlhkosti pak lze rozli&scaron;ovat 3&nbsp;z&aacute;kladn&iacute; skupiny rostlin a živočichů:</p>
    <ul>
    <li><strong>hygrofyty</strong> a <strong>hygrofiln&iacute; živočichov&eacute;</strong> (preferuj&iacute; vysokou vlhkost)</li>
    <li><strong>mezofyty </strong>a <strong>mezofiln&iacute; živočichov&eacute;</strong> (preferuj&iacute; středn&iacute; vlhkost)</li>
    <li><strong>xerofyty</strong> a <strong>xerofiln&iacute; živočichov&eacute;</strong> (preferuj&iacute; such&eacute; podm&iacute;nky)</li>
    </ul>
    <h4>Adaptace organismů na přemokřen&iacute; a sucho</h4>
    <p>U rostlin vlhk&yacute;ch prostřed&iacute; se často objevuj&iacute; adaptace na přemokřen&iacute;, např&iacute;klad:</p>
    <ul>
    <li><strong>aerenchym </strong>(provzdu&scaron;ňovac&iacute; pletivo, např. u s&iacute;tin)</li>
    <li><strong>aktivn&iacute; transport kysl&iacute;ku do kořenů</strong> (např. r&aacute;kos)</li>
    <li><strong>vzdu&scaron;n&eacute; kořeny </strong>(např. mangrove)</li>
    <li><strong>mělk&eacute; kořeny</strong> (např. smrky)</li>
    </ul>
    <p>Rostliny rostouc&iacute; v such&yacute;ch prostřed&iacute;ch rozdělujeme dle adaptačn&iacute;ho způsobu do dvou hlavn&iacute;ch skupin:</p>
    <ul>
    <li><strong>sukulenty</strong> (rostliny s parenchymatick&yacute;mi pletivy z&aacute;soben&yacute;mi vodou, jsou uvnitř "&scaron;ťavnat&eacute;", např. aloe, netřesk)</li>
    <li><strong>sklerofyty</strong> (rostliny se zv&yacute;&scaron;en&yacute;m pod&iacute;lem tvrd&yacute;ch konenchymů a&nbsp;sklerenchymů, jsou tvrd&eacute;, např. m&aacute;čka ladn&iacute;)</li>
    </ul>
    <p>Adaptace živočichů proti ztr&aacute;t&aacute;m vody v such&yacute;ch prostřed&iacute; jsou různ&eacute;, v&nbsp;z&aacute;kladu je člen&iacute;me do třech skupin:</p>
    <ul>
    <li><strong>morfologick&eacute; adaptace</strong> (těln&iacute; pokryv, např. zrohovatěl&aacute; pokožka)</li>
    <li><strong>fyziologick&aacute; adaptace</strong> (např. moč je vylučov&aacute;na mimoř&aacute;dně koncentrovan&aacute;)</li>
    <li><strong>etologick&aacute; adaptace</strong> (přizpůsoben&iacute; aktivity, např. nočn&iacute; život v pou&scaron;i či tzv. estivace = "letn&iacute; sp&aacute;nek")</li>
    </ul>
    <h3>Rostliny ČR ve vztahu k vlhkosti</h3>
    <p>V tabulce n&iacute;že jsou uvedeny 3&nbsp;rostliny s n&iacute;zkou a vysokou <Link to="/biogeografie/ekologicka/ekologicke-faktory/#ellenberg">Ellenbergovskou indikačn&iacute; hodnotou</Link> běžně se vyskytuj&iacute;c&iacute; na &uacute;zem&iacute; Česka. V z&aacute;vorce je pod česk&yacute;m a latinsk&yacute;m jm&eacute;nem druhu uvedena dan&aacute; Ellenbergova indikačn&iacute; hodnota (EIH).</p>
    <figure class="wp-block-table">
    <table>
    <thead>
    <tr>
    <th>N&iacute;zk&aacute; indikačn&iacute; hodnota (EIH)</th>
    <th>Vysok&aacute; indikačn&iacute; hodnota (EIH)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>kavyl skaln&iacute;<br /><em>Stipa eriocaulis</em><br /><em>M1</em></td>
    <td>&scaron;&iacute;dlatka jezern&iacute;<br /><em>Iso&euml;tes lacustris</em><br /><em>M12</em></td>
    </tr>
    <tr>
    <td>ostřice n&iacute;zk&aacute;<br /><em>Carex humilis</em><br /><em>M2</em></td>
    <td>orobinec &uacute;zkolist&yacute;<br /><em>Thypa angustifolia</em><br /><em>M10</em></td>
    </tr>
    <tr>
    <td>mateř&iacute;dou&scaron;ka časn&aacute;<br /><em>Thymus praecox</em><br /><em>M2</em></td>
    <td>ol&scaron;e lepkav&aacute;<br /><em>Alnus glutinosa</em><br /><em>M9</em></td>
    </tr>
    </tbody>
    </table>
    </figure>
    <hr />
    <h2>Faktor vlastnost&iacute; půdy</h2>
    <p>Z faktorů vlastnost&iacute; půdy jsou jedny z nejdůležitěj&scaron;&iacute;ch půdn&iacute; reakce prostřed&iacute; (pH). Půdn&iacute; reakce jsou podm&iacute;něny koncentrac&iacute; vod&iacute;kov&yacute;ch iontů H+ a z&aacute;vis&iacute; zejm&eacute;na na matečn&eacute; hornině, d&aacute;le pak na abiotick&yacute;ch (vyplavov&aacute;n&iacute; a vzl&iacute;n&aacute;n&iacute;) a biotick&yacute;ch procesech v půdě. Vegetace je schopn&aacute; postupem času modifikovat pH prostřed&iacute;, např. smrk snižuje pH a javor naopak zvy&scaron;uje. <Link to="/ekologie-zivotni-prostredi/ph-reakce-prostredi/">V&iacute;ce o půdn&iacute;ch reakc&iacute;ch (pH)</Link> v kapitole <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a ŽP</Link>.</p>
    <p>Obsah živin je t&eacute;ž důležit&yacute;m faktorem, zejm&eacute;na obsah makroelementů (N, P, K, S, Ca, Mg) a mikroelementů (Fe, Mn, Zn, Cu, Mo, B). <Link to="/ekologie-zivotni-prostredi/ph-reakce-prostredi/">V&iacute;ce o vlivu nadbytku či nedostatku živin</Link> v kapitole <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a ŽP</Link>.</p>
    <p>Nezanedbateln&yacute;m faktorem jsou takt&eacute;ž fyzik&aacute;ln&iacute; vlastnosti půd, zejm&eacute;na pak zrnitost. Ta ovlivňuje předev&scaron;&iacute;m půdn&iacute; vlhkost, zat&iacute;mco těžk&eacute; půdy (j&iacute;l) jsou nepropustn&eacute; pro vodu a způsobuj&iacute; přemokřen&iacute; povrchu, lehk&eacute; půdy (p&iacute;sek) maj&iacute; velkou propustnost a rychle vysychaj&iacute;. Středně těžk&eacute;, hlinit&eacute; půdy pak maj&iacute; optim&aacute;ln&iacute; vsakov&aacute;n&iacute; vody.</p>
    <h3>Extr&eacute;mn&iacute; stanovi&scaron;tě v Česku</h3>
    <h4>Slaniska</h4>
    <p>Slaniska jsou stanovi&scaron;tě s vysokou salinitou, kter&aacute; je d&aacute;na vysokou koncentrac&iacute; chloridů, uhličitanů, s&iacute;ranů a dusičnanů v&aacute;pn&iacute;ku, hořč&iacute;ku, sod&iacute;ku a drasl&iacute;ku. V Česku existuje slanisko u Nesytu.</p>
    <p>Slaniska vyžaduj&iacute; od rostlin specifick&eacute; adaptace, rostliny adaptovan&eacute; na vy&scaron;&scaron;&iacute; koncentrace sol&iacute; se naz&yacute;vaj&iacute; halofyty. Mezi česk&eacute; halofyty patř&iacute; např. slanorožec rozprostřen&yacute; (<Link to="/biogeografie/ekologicka/ekologicke-faktory/#ellenberg">EIH</Link> pro salinitu 9).</p>
    <h4>Hadce (Serpentinity)</h4>
    <p>Hadce (nebo tak&eacute; serpentinity) jsou ultrabazick&eacute; metamorfovan&eacute; horniny tmavozelen&eacute; až čern&eacute; barvy, kter&eacute; maj&iacute; vysok&yacute; obsah hořč&iacute;ku a často i těžk&yacute;ch kovů. V hadc&iacute;ch je naopak n&iacute;zk&yacute; obsah v&aacute;pn&iacute;ku. Mezi v&yacute;znamn&eacute; hadcov&eacute; lokality ČR patř&iacute; Mohelno, Borek u Chotěboře, Slavkovsk&yacute; les, Křemže, Mlad&aacute; Vožice, Ra&scaron;kov či Star&eacute; Ransko.</p>
    <p>Rostliny adaptovan&eacute; na hadcov&eacute; podlož&iacute; se naz&yacute;vaj&iacute; serpentinofyty. Mezi typick&yacute; česk&yacute; serpentinofyt lze řadit slezin&iacute;k hadcov&yacute;.</p>
    <h3>Rostliny ČR ve vztahu k pH půdy a dostupnosti živin</h3>
    <p>V tabulce n&iacute;že jsou uvedeny 3&nbsp;rostliny s n&iacute;zkou a vysokou <Link to="/biogeografie/ekologicka/ekologicke-faktory/#ellenberg">Ellenbergovskou indikačn&iacute; hodnotou</Link> běžně se vyskytuj&iacute;c&iacute; na &uacute;zem&iacute; Česka. V z&aacute;vorce je pod česk&yacute;m a latinsk&yacute;m jm&eacute;nem druhu uvedena dan&aacute; Ellenbergova indikačn&iacute; hodnota (EIH). Prvn&iacute; tabulka zachycuje EIH ve vztahu k pH půdy, druh&aacute; tabulka pak EIH vztahuj&iacute;c&iacute; se k dostupnosti živin.</p>
    <figure class="wp-block-table">
    <table>
    <thead>
    <tr>
    <th>N&iacute;zk&aacute; indikačn&iacute; hodnota (EIH)</th>
    <th>Vysok&aacute; indikačn&iacute; hodnota (EIH)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>kyhanka sivolist&aacute;<br /><em>Andromeda polifolia</em><br /><em>R1</em></td>
    <td>pěchava v&aacute;pnomiln&aacute;<br /><em>Sesleria caerulea</em><br /><em>R9</em></td>
    </tr>
    <tr>
    <td>vřes obecn&yacute;<br /><em>Calluna vulgaris</em><br /><em>R1</em></td>
    <td>kamejka modronachov&aacute;<br /><em>Buglossoides purpurocaerulea</em><br /><em>R8</em></td>
    </tr>
    <tr>
    <td>borůvka čern&aacute;<br /><em>Vaccinium myrtillus</em><br /><em>R2</em></td>
    <td>jilm habrolist&yacute;<br /><em>Ulmus minor</em><br /><em>R8</em></td>
    </tr>
    </tbody>
    </table>
    </figure>
    <figure class="wp-block-table">
    <table>
    <thead>
    <tr>
    <th>N&iacute;zk&aacute; indikačn&iacute; hodnota (EIH)</th>
    <th>Vysok&aacute; indikačn&iacute; hodnota (EIH)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>rosnatka okrouhlolist&aacute;<br /><em>Drosea rotundifolia</em><br /><em>N1</em></td>
    <td>kopřiva dvoudom&aacute;<br /><em>Urtica dioica</em><br /><em>N9</em></td>
    </tr>
    <tr>
    <td>klikva bahenn&iacute;<br /><em>Vaccinium oxycoccos</em><br /><em>N1</em></td>
    <td>česnek medvěd&iacute;<br /><em>Allium ursinum</em><br /><em>N</em>8</td>
    </tr>
    <tr>
    <td>koniklec velkokvět&yacute; <br /><em>Pulsatilla grandis</em><br /><em>N2</em></td>
    <td>bez čern&yacute;<br /><em>Sambucus nigra</em><br /><em>N8</em></td>
    </tr>
    </tbody>
    </table>
    </figure>
<hr />
  <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Abiotické ekologické faktory</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/ekologicke-faktory/"><button className="inv">&larr; Ekologické faktory, valence, nika</button></Link>
    <Link to="/biogeografie/ekologicka/bioticke-interakce"><button className="inv">Biotické interakce &rarr;</button></Link>
    </BtnWrap>
  </div></ImgCon><hr />
  </Layout>
)
